import map_point_time from "../../img/Maps/grafico_pastoreio.png"
import styles from "./Sub3ResultsPage.module.css"
function Sub3ResultsPage() {

  return (
    <div className={styles.flock}>
      <img src={map_point_time} alt="map"/>
    </div>
  );
}

export default Sub3ResultsPage;
