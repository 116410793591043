import Outcome from './Outcome';

import styles from './Sub1ResultsPage.module.css'

import { useTranslation } from 'react-i18next';

function Sub1ResultsPage() {
    const { t } = useTranslation();
    const artcient = [
        {
            title: 'Development of an IoT-Based Device for Data Collection on Sheep and Goat Herding in Silvopastoral Systems.',
            authors: 'Araujo, M., Leitão, P., Castro, M., Castro, J., Bernuy, M.',
            year: "2024",
            place: 'Sensors, 24 (17), 5528',
            link: 'https://www.mdpi.com/2929122'
            
        },
        {
            title: 'Changes on the Climatic Edge: Adaptation of and Challenges to Pastoralism in Montesinho (Northern Portugal).',
            authors: 'Castro, J., Castro, M., & Gomez-Sal, A.',
            year: "2021",
            place: 'Mountain Research and Development, 41(4), R29-R37.',
            link: 'https://bioone.org/journals/mountain-research-and-development/volume-41/issue-4/MRD-JOURNAL-D-21-00010.1/Changes-on-the-Climatic-Edge--Adaptation-of-and-Challenges/10.1659/MRD-JOURNAL-D-21-00010.1.full'
        },
    ]

    const conint = [
        {
            title: 'Traditional pastoralism facing climate change: identifying watering constraints and local adaptations in Trás-os-Montes, Portugal.',
            authors: 'Oliveira, A.C.F., Seripieri, V.H.M., Aleixo-Pais, I., Castro, J., Castro, M.',
            year: '2024',
            place: 'IFSA2024 Systemic Change for Sustainable Futures',
            link: null,
            country: 'Portugal',
        },
        {
            title: 'Knowledge and grazing journeys: dialogues on adaptive responses to climate change in Montesinho (Portugal).',
            authors: 'Frazão-Moreira, A., Castro, J., Castro, J.P., Aleixo-Pais, I., Amieira, J., Seripieri, V., Castro, M. ',
            year: '2024',
            place: 'The 18th Congress of the International Society of Ethnobiology,',
            link: 'http://hdl.handle.net/10198/31168',
            country: 'Marocco',
        },
        {
            title: 'Pastoral Landscapes in north-eastern Portugal: drought, changes, and vulnerabilities.',
            authors: 'Castro, J., Aleixo-Pais, I., Frazão-Moreira, A., Castro, M.',
            year: '2023',
            place: 'International Congress Uncertain Landscapes.',
            link: 'https://uncertainlandscapes.weebly.com/uploads/1/4/3/4/143464747/uncertain_abstracts%7C2023.pdf',
            country: 'Portugal'
        },
        {
            title: 'Mapeando memórias e percursos da pastorícia. Respostas adaptativas às alterações climáticas em Montesinho (Portugal).',
            authors: 'Frazão-Moreira, A., Castro, J.,  Castro M. ',
            year: '2023',
            place: 'XVI Congreso Internacional de Antropología.',
            link: 'https://asaee-antropologia.org/wp-content/uploads/2023/08/PROGRAMA-COMPLETO-XVI-Congreso-ASAEE_27_07_2023.pdf',
            country: 'Espanha'
        },
        {
            title: 'The transformation of pastoral landscape in montesinho natural park (1995 – 2021).',
            authors: 'Seripieri V., Castro J., Silva M., Castro M.',
            year: '2023',
            place: 'IUFRO Forest Environment DIV8 Conference 2023.',
            link: 'https://iufro2023.uevora.pt/wp-content/uploads/2023/12/Final-Book-of-Abstracts-IUFRO-DIV8-Conference-2023.pdf',
            country: 'Portugal'
        }
    ]

    const comnac = [{
        title: 'Smart Small Ruminant Device: An IoT-Based System for Real-Time Monitoring and Management of Sheep and Goat Mobility.',
        authors: 'Araujo, M., Leitão, P., Castro, M., Castro, J., Bernuy, M.',
        year: '2024',
        place: 'SASYR (Symposium of Applied Science for Young Researchers). Bragança.',
        link: 'http://sasyr.ipb.pt/EN_index.html'
    },
    {
        title: 'Knowledge and grazing journeys: dialogues on adaptive responses to climate change in Montesinho (Portugal).',
        authors: 'Frazão-Moreira, A., Castro, J., Castro, J.P., Aleixo-Pais, I., Amieira, J., Seripieri, V., Castro, M.',
        year: '2024',
        place: 'Encontro Ciência 2024. Alfândega do Porto',
        link: 'https://app.encontrociencia.pt/exhibitor/22710'
    },
    {
        title: 'Roda de conversa: Reconfiguração de ‘Baldios’: potencialidades e desafios na era pós-capitalista para o reforço comunitário e a sustentabilidade.',
        authors: 'Castro, J., Fernandes, J.M.A., Costa, L.R., Carvalho, A., Morais, J.',
        year: '2024',
        place: 'Comuns: III Encontro de Ecologia Política. Coimbra.',
        link: 'https://www.ces.uc.pt/ficheiros2/files/ECOP2024 - LIVRO DE RESUMOS E BIOS_Coimbra 19 abril.pdf'
    },
    {
        title: 'Relembrar em comum: memórias e vivências do sistema agro-silvo-pastoril em Montesinho',
        authors: 'Amieira, J., Seripieri, V., Castro, J., Frazão-Moreira, A., Castro, M. ',
        year: '2024',
        place: 'Comuns: III Encontro de Ecologia Política. Lisboa.',
        link: 'https://www.ces.uc.pt/ficheiros2/files/ECOP2024 - LIVRO DE RESUMOS E BIOS_Lisboa 11-13 abril.pdf'
    },
    {
        title: 'A pastorícia, transformações e resistência, pelo olhar das mulheres.',
        authors: 'Aleixo-Pais, I., Castro, J., Castro, J.P., Frazão-Moreira, A., Castro, M. ',
        year: '2024',
        place: 'Comuns: III Encontro de Ecologia Política. Lisboa.',
        link: 'https://www.ces.uc.pt/ficheiros2/files/ECOP2024 - LIVRO DE RESUMOS E BIOS_Lisboa 11-13 abril.pdf'
    },
    {
        title: 'Transformação da paisagem silvopastoril no Parque Natural de Montesinho num cenário de alterações climáticas.',
        authors: 'Castro, J., Castro, J. P., Castro, M.',
        year: '2022',
        place: '9º Congresso Florestal Nacional: livro de resumos. Funchal.',
        link: '2022',
        country: '',
    }
    ]

    const teses = [
        {
            title: 'Data acquisition system using IoT to monitor sheep and goat mobility.',
            authors: 'Araujo, M. ',
            year: '2024',
            place: 'Dissertação de Mestrado, Instituto Politécnico de Bragança',
            link: 'http://hdl.handle.net/10198/30129'
        },
        {
            title: 'Characterization of Grazing Patterns: Analyzing Sheep Herd Dynamics in Montesinho Natural Park.',
            authors: 'Fellahi, M. ',
            year: '2024',
            place: 'Dissertação de Mestrado, Instituto Politécnico de Bragança',
            link: 'http://hdl.handle.net/10198/30715'
        },
        {
            title: 'Characterization of Grazing Patterns: Analyzing Goat Herd Dynamics in Montesinho Natural Park.',
            authors: 'Issam, B.',
            year: '2024',
            place: 'Dissertação de Mestrado, Instituto Politécnico de Bragança.',
            link: 'http://hdl.handle.net/10198/30707'
        },
        {
            title: 'A transformação da paisagem pastoril no Parque Natural Montesinho (1995 – 2022).',
            authors: 'Seripieri, V. H. M.',
            year: '2023',
            place: 'Dissertação de Mestrado, Instituto Politécnico de Bragança',
            link: 'http://hdl.handle.net/10198/28745'
        },
        {
            title: 'Dinâmica e disponibilidade hídrica nas zonas de pastoreio no Parque Natural de Montesinho.',
            authors: 'Oliveira, A. C. F.',
            year: '2023',
            place: 'Dissertação de Mestrado, Instituto Politécnico de Bragança, Portugal.',
            link: 'http://hdl.handle.net/10198/29203'
        }
    ]

    const workshops=[
        {
            title: 'Workshop com stakeholders: Pastorícia de ovinos e caprinos no Parque Nacional de Montesinho.',
            year: '2023',
            place: 'Encontro Ciência 2023. Instituto Politécnico de Bragança, Portugal.',
            authors: null,
            link: null
        },
        {
            title: 'Workshops com população local: Percursos históricos da pastorícia e memórias da paisagem.',
            year: '2024',
            place: 'Palácios, Soeira e Parâmio, Portugal.',
            authors: null,
            link: null
        },
        {
            title: 'Workshop com stakeholders: restituição de resultados e discussão das práticas de pastoreio adaptativas às alterações climática.',
            year: '2024',
            place: 'Bragança. Portugal',
            authors: null,
            link: null
        },
        {
            title: 'Workshops com população local: restituição de resultados e discussão das práticas de pastoreio adaptativas às alterações climática.',
            year: '2024',
            place: 'Palácios e Soeira. Portugal',
            authors: null,
            link: null
        }
    ]

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <h2> {t('Title1', { ns: 'Results' })} </h2>
                <p>{t('click', { ns: 'Results' })}</p>
            </div>
            <div className={styles.outcomes}>
                <Outcome
                    title={t('subtitle1', { ns: 'Results' })}
                    num={artcient.length}
                    works={artcient}
                />
                <Outcome
                    title={t('subtitle2', { ns: 'Results' })}
                    num={conint.length}
                    works={conint}
                />
                <Outcome
                    title={t('subtitle3', { ns: 'Results' })}
                    num={comnac.length}
                    works={comnac}
                />
                <Outcome
                    title={t('subtitle4', { ns: 'Results' })}
                    num={teses.length}
                    works={teses}
                />
                <Outcome 
                title={t('subtitle5', { ns: 'Results' })}
                num={workshops.length}
                works={workshops}
                />
            </div>
        </div>
    )
}


export default Sub1ResultsPage;