import styles from './LocationPage.module.css'

import matomapa from '../img/Maps/Montesinho.png'
import matosinhoCoord from '../../src/Maps/coordenadas.json'
import { MapContainer, Polygon, Popup, TileLayer } from 'react-leaflet';
import { useTranslation } from 'react-i18next';

function LocationPage() {

    const matosinho = matosinhoCoord

    const position = [41.88, -6.85]; // Coordenadas iniciais (latitude, longitude)

    const limeOptions1 = { color: "#fff" }

    const { t } = useTranslation();

    return (

        <section className={styles.container}>
            <h2> {t('Title', { ns: 'Location' })}</h2>
            <div className={styles.description}>
                <p>{t('description', { ns: 'Location' })}</p>
            </div>
            <div className={styles.title}>
                <img src={matomapa} alt="mapa"></img>
                <p>{t('subtitle1', { ns: 'Location' })} <br />
                    {t('subtitle2', { ns: 'Location' })}</p>
            </div>
            <div className={styles.title}>
                <h2> Montesinho</h2>
            </div>
            {<div className={styles.mapMontesinho}>
                <MapContainer
                    center={position}
                    zoom={10}
                    scrollWheelZoom={true}
                    style={{ height: "500px", width: "100%" }} // Definindo altura e largura do mapa
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}"
                    />
                    <Polygon
                        pathOptions={limeOptions1}
                        positions={matosinho}
                    ><Popup className={styles.map}>
                            <h4>Parque Natural de Montesinho </h4>
                            <p>74495 ha</p>
                        </Popup>
                    </Polygon>

                </MapContainer>
            </div>}
        </section>
    )
}


export default LocationPage;