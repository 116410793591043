import styles from './Team_PartnersPage.module.css'

import { FaHatCowboy } from "react-icons/fa6";

import marina from '../img/Team/marinacastro.jpg'
import jose from '../img/Team/josecastro.png'
import amelia from '../img/Team/amelia.jpg'
import joaopaulo from '../img/Team/joaocastro.jpg'
import antonio from '../img/Team/antonio.jpg'

import isa from '../img/Team/isapais.jpg'
import vitor from '../img/Team/vitor.png'
import ana from '../img/Team/ana.jpg'
import mateus from '../img/Team/mateus.jpg'
import joaoamieira from '../img/Team/joaoamieira.jpg'

import acob from '../img//Partners/ACOB.png'
import ancras from '../img//Partners/ANCRAS.jpg'
import icnf from '../img//Partners/ICNF.png'
import green1 from '../img//logo/green1.png'
import green2 from '../img//logo/green2.png'
import OB from '../img//logo/OB.jpg'


import Team from '../components/Team'
import Partners from '../components/Partners'

import { useTranslation } from 'react-i18next';

function TeamPartner() {
    const { t } = useTranslation();
    return (
        <section>
            <div className={styles.title}>
                <h2>{t('title1', { ns: 'Team' })}</h2>
            </div>
            <div className={styles.team}>
                <Team
                    name="Marina Castro"
                    photo={marina}
                    desc="(Investigadora Responsável) CIMO,LA SusTEC (IPB) "
                    link="https://cimo.ipb.pt/index.php?r=olderresearcher/view&id=57"
                />
                <Team
                    name="Amélia Frazão-Moreira"
                    photo={amelia}
                    desc="(Co-investigadora Responsável) CRIA, NOVA FCSH "
                    link="https://cria.org.pt/pt/people/amelia-frazao-moreira"
                />
                <Team
                    name="José Castro"
                    photo={jose}
                    desc="(Investigador) IPB"
                    link="https://www.ipb.pt/~mzecast/"
                />
                <Team
                    name="João Paulo Castro"
                    photo={joaopaulo}
                    desc="(Investigador) CIMO (IPB) "
                    link="https://cimo.ipb.pt/index.php?r=olderresearcher/view&id=31"
                />
            </div>
            <div className={styles.title}>
                <h2>{t('title2', { ns: 'Team' })}</h2>
            </div>
            <div className={styles.team}>
                <Team
                    name="António Gómez Sal"
                    photo={antonio}
                    desc="Universidad de Alcalá, Espanha"
                    link="https://www.uah.es/es/estudios/profesor/Antonio-Gomez-Sal/"
                />
            </div>
            <div className={styles.title}>
                <h2>{t('title3', { ns: 'Team' })}</h2>
            </div>
            <div className={styles.team}>
                <Team
                    name="Isa Aleixo-Pais"
                    photo={isa}
                    desc="(Bolseira de Investigação Pós-doutoral) CIMO, LA SusTEC (IPB)"
                    link="https://cimo.ipb.pt/index.php?r=researcher/view&id=954"
                />
                <Team
                    name="Ana Carolina Oliveira "
                    photo={ana}
                    desc=" (Bolseiro de Investigação) CIMO, LA SusTEC (IPB)"
                    link="https://www.linkedin.com/in/ana-carolina-farias-de-oliveira-b330751b4/"
                />
                <Team
                    name="João Amieira"
                    desc="(Bolseiro de Investigação) CRIA-NOVA FCSH / CIMO (IPB)"
                    link={null}
                    photo={joaoamieira}
                />
                <Team
                    name="Mateus Araujo"
                    photo={mateus}
                    desc="(Estudante de Mestrado) CEDRI, LA SusTEC (IPB)"
                    link="https://www.linkedin.com/in/mateus-costa-775340235/"
                />
                <Team
                    name="Vítor Seripieri "
                    photo={vitor}
                    desc="(Bolseiro de Investigação) CIMO, LA SusTEC (IPB)"
                    link="https://www.linkedin.com/in/vitor-mistro-40591b262/"
                />
            </div>
            <div className={styles.title}>
                <h2>{t('title4', { ns: 'Team' })}</h2>
            </div>
            <div className={styles.Farmers}>
                <ul>
                    <li><FaHatCowboy id={styles.hat} /> Armindo Veiga e Arminda Veiga – Vinhais</li>
                    <li><FaHatCowboy id={styles.hat} /> Adriano da Silva e Vera Silva  – Soeira</li>
                    <li><FaHatCowboy id={styles.hat} /> João Carroceiras  e Paula Carroceiras  - Maçãs</li>
                    <li><FaHatCowboy id={styles.hat} /> Humberto Figueiredo e Marina Lopes Figueiredo  – Zeive</li>
                    <li><FaHatCowboy id={styles.hat} /> Manuel João do Cubo e Irene Roque do Cubo – Palácios</li>
                    <li><FaHatCowboy id={styles.hat} /> Ramiro Veiga Pires – São Julião de Palácios</li>
                </ul>
            </div>
            <div className={styles.title}>
                <h2>{t('title5', { ns: 'Team' })}</h2>
            </div>
            <div className={styles.partners}>
                <Partners
                    name="ACOB"
                    link="http://www.acob.org.pt"
                    desc="Associação Nacional de Criadores de Ovinos da Raça Churra Galega Bragançana"
                    logo={acob}
                />
                <Partners
                    name="ANCRAS"
                    link="http://www.ancras.pt"
                    desc="Associação Nacional de Caprinicultores de Raça Serrana"
                    logo={ancras}
                />
                <Partners
                    name="ICNF"
                    link="https://www.icnf.pt"
                    desc="Instituto da Conservação da Natureza e das Florestas"
                    logo={icnf}
                />
                <Partners
                    name="OB"
                    link={null}
                    desc="Observatório de Montesinho"
                    logo={OB}
                />
                <Partners
                    name="OPPCB"
                    link={null}
                    desc="Organização de Produtores Pecuários do Concelho de Bragança"
                    logo={green2}
                />
                <Partners
                    name="OPPCV"
                    link={null}
                    desc="Organização de Produtores Pecuários do Concelho de Vinhais"
                    logo={green1}
                />
            </div>

        </section>
    )
}

export default TeamPartner;